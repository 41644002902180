<template>
  <div id="app">
    <MainNavigation/>
  </div>
</template>

<script>
import MainNavigation from './components/MainNavigation.vue'
//import { GChart } from 'vue-google-charts/legacy'

export default {
  name: 'App',
  components: {
    MainNavigation,
    //GChart
  }
}
</script>

