import $vuetify from 'vuetify/es5/locale/de'

export default {
    // vuetify defaults (buttons and text blocks in data-tables etc...)
    $vuetify,

    // custom translations here:
    welcome: 'Willkommen!',
    darkMode: 'Dunkler Modus',
    lightMode: 'Heller Modus',
    routing: {
        "Home": "Home",
        "Sensoren": 'Sensoren',
        "Zaehler":"Zähler",
        "Chichi":"Chichi",
        "Users":"Nutzer",
        "User":"Nutzer",
        "Music":"Musik",
        "Flowers":"Blumen"
    },
    flowers: {
        "moisture":"Feuchtigkeit",
        "temperature":"Temperatur",
        "light":"Helligkeit",
        "conductivity":"Düngung"
    }
    
}
