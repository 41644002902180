<template>
 <v-container class="fill-height container--fluid">
    <v-row class="fill-height">
      <v-col cols="12" class="d-flex justify-center align-center">
        
        <iframe style="border-radius:12px" title="music" src="https://open.spotify.com/embed/playlist/2tFeiGshki05pbNgaxfW0V?utm_source=generator&theme=0" width="100%" height="100%" allowfullscreen="" allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"></iframe>
      </v-col>
    </v-row>
  </v-container>

</template>





<script>



export default {
  name: "Music",
  data: () => ({
    test: "test",
    rws: null,
    meter: { },
    metrics: null,
    chartOptions: {
      height: 200,
      width: 450,
      backgroundColor: { fill: "transparent" },
      legend: { position: "bottom" },
      textStyle: {
        color: "#808080",
      },
      chart: {
        //title: "Messwerte",
        subtitle: "",
        curveType: "function",
        legend: { position: "bottom" },
      },
    },
    misc: {
      snackbarText: "",
      snackbar: false,
      switchValue: 0,
      device: "",
      deviceIcon: "",
      index: 0,
      weekdays: [0, 1, 2, 3, 4],
      hostname: location.host,
    },
  }),
  computed: {
    computedCols() {
      if (this.$vuetify.breakpoint.xs) {
        return "6";
      } else if (this.$vuetify.breakpoint.sm) {
        return "4";
      } else if (this.$vuetify.breakpoint.md) {
        return "4";
      } else if (this.$vuetify.breakpoint.lg) {
        return "2";
      } else if (this.$vuetify.breakpoint.xl) {
        return "2";
      } else {
        return "1";
      }
    },
    computedWidth() {
      console.log(this.$vuetify.breakpoint.name);
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return 190;
        case "sm":
          return 250;
        case "md":
          return 250;
        case "lg":
          return 250;
        case "xl":
          return 250;
        default:
          return 250;
      }
    },
  },
  methods: {
    fetchMeterValues: function () {
      const requestOptions = {
        method: "POST",
        headers: { "x-access-token":this.$store.state.token,"Content-Type": "application/json" },
        body: JSON.stringify("fetchMeter"),
      };
      fetch(
        location.protocol + "//" + this.misc.hostname + "/api/meter/set",
        requestOptions
      )
        .then((response) => response.json())
        .then((data) => {
          this.meter = data;
          console.log(this.meter);
        })
        .catch((e) => {
          this.misc["snackbarText"] = "Error " + e;
          this.misc["snackbar"] = true;
        });
    },
    checkHost: function () {
      console.log(location.host);
      if (location.host == "badboard.badcloud.eu" ||  location.host == "test.badcloud.eu") {
        this.$store.state.globalData.hostname = location.host;
        this.misc.hostname = this.$store.state.globalData.hostname;
      } else {
        this.$store.state.globalData.hostname = "192.168.0.106:7000";
        this.misc.hostname = this.$store.state.globalData.hostname;
      }
      console.log(this.$store.state.globalData.hostname);
    },
  },
  watch: {
    sliderValueWatch: function (a, b) {
      console.log(a, b, this.misc.switchValue);
    },
  },
  mounted() {
    this.checkHost();   
  },
  components: {

  },
};
</script>



<style scoped>
#ifrm {
  border: 0;
}

</style>
