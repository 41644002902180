import Vue from 'vue'
import Vuetify from 'vuetify'
import 'vuetify/dist/vuetify.min.css'
import '@mdi/font/css/materialdesignicons.css' // Ensure you are using css-loader

Vue.use(Vuetify)
import de from 'vuetify/es5/locale/de'
import en from 'vuetify/es5/locale/en'
import fr from 'vuetify/es5/locale/fr'
import i18n from "@/plugins/i18n";

const locales = {
    de: de,
    en: en,
    fr: fr
}

const current = navigator.language.split('-')[0]

const opts = {
    lang: {
        t: (key, ...params) => i18n.t(key, params),
    },
    locales,
    current,
    icons: {
        iconfont: 'mdi', // default - only for display purposes
    }, theme: {
        themes: {
            light: {
                primary: '#8dbe48',
                secondary: '#7e7f81',
                accent: '#009688',
                error: '#f41a0a',
                warning: '#ff8922',
                info: '#03a9f4',
                success: '#35e03a',
                zcolor: '#8dbe48',
                bwThemed: '#000000',
            },
            dark: {
                primary: '#8dbe48',
                accent: '#009688',
                bwThemed: '#ffffff',
            }
        }
    }
}

export default new Vuetify(opts)
