import $vuetify from 'vuetify/es5/locale/en'

export default {
    // vuetify defaults (buttons and text blocks in data-tables etc...)
    $vuetify,

    // custom translations here:
    welcome: 'Welcome!',
    darkMode: 'Dark Mode',
    lightMode: 'Light mode',
    routing: {
        "Home": "Home",
        "Zaehler":"Counter",
        "Sensoren":"Sensors",
        "Chichi":"ChiChi",
        "Users":"Users",
        "User":"User",
        "Music":"Music",
        "Flowers":"Flowers"
    },
    flowers: {
        "moisture":"moisture",
        "temperature":"temperature",
        "light":"light",
        "conductivity":"conductivity"
    }
}
