import Vue from 'vue'
import Vuex from 'vuex'




Vue.use(Vuex)

export const store = new Vuex.Store({
    state: {
        globalData: {
            hostname: "",
            test: '',
            test2: "",
            metrics: {
                "actuators": [
                    {
                        "hostname": "rollo-links",
                        "icon": "mdi-umbrella-beach",
                        "name": "Rollo-Links",
                        "powerMeter": 0,
                        "protected": 0,
                        "value": 0
                    },
                    {
                        "hostname": "rollo-rechts",
                        "icon": "mdi-umbrella-beach",
                        "name": "Rollo-Rechts",
                        "powerMeter": 0,
                        "protected": 0,
                        "value": 0
                    }
                ],
                "sensors": [
                    {
                        "hostname": "wohnzimmer",
                        "humidity": "52.8",
                        "icon": "mdi-sofa",
                        "metrics": {
                            "humidity": [
                                [
                                    "time",
                                    "humid"
                                ]
                            ],
                            "temperature": [
                                [
                                    "time",
                                    "temp"
                                ]
                            ]
                        },
                        "name": "Wohnzimmer",
                        "powerMeter": 0,
                        "protected": 0,
                        "temperature": "21.6",
                        "value": null
                    },
                    {
                        "hostname": "aussen",
                        "humidity": "99.9",
                        "icon": "mdi-forest",
                        "metrics": {
                            "humidity": [
                                [
                                    "time",
                                    "humid"
                                ]
                            ],
                            "temperature": [
                                [
                                    "time",
                                    "temp"
                                ]
                            ]
                        },
                        "name": "Außen",
                        "powerMeter": 0,
                        "protected": 0,
                        "temperature": "3.4",
                        "value": null
                    },
                    {
                        "hostname": "badezimmer",
                        "humidity": "57.2",
                        "icon": "mdi-bathtub",
                        "metrics": {
                            "humidity": [
                                [
                                    "time",
                                    "humid"
                                ]
                            ],
                            "temperature": [
                                [
                                    "time",
                                    "temp"
                                ]
                            ]
                        },
                        "name": "Bad",
                        "powerMeter": 0,
                        "protected": 0,
                        "temperature": "19.6",
                        "value": null
                    },
                    {
                        "hostname": "schlafzimmer",
                        "humidity": "58.7",
                        "icon": "mdi-bed",
                        "metrics": {
                            "humidity": [
                                [
                                    "time",
                                    "humid"
                                ]
                            ],
                            "temperature": [
                                [
                                    "time",
                                    "temp"
                                ]
                            ]
                        },
                        "name": "Schlafzimmer",
                        "powerMeter": 0,
                        "protected": 0,
                        "temperature": "19.25",
                        "value": null
                    },
                    {
                        "hostname": "arbeitszimmer",
                        "humidity": "67.1",
                        "icon": "mdi-tools",
                        "metrics": {
                            "humidity": [
                                [
                                    "time",
                                    "humid"
                                ],
                            ],
                            "temperature": [
                                [
                                    "time",
                                    "temp"
                                ],
                            ]
                        },
                        "name": "Arbeitszimmer",
                        "powerMeter": 0,
                        "protected": 0,
                        "temperature": "18.9",
                        "value": null
                    },
                    {
                        "hostname": "flur",
                        "humidity": 0,
                        "icon": "mdi-door",
                        "metrics": {
                            "humidity": [
                                [
                                    "time",
                                    "humid"
                                ]
                            ],
                            "temperature": [
                                [
                                    "time",
                                    "temp"
                                ]
                            ]
                        },
                        "name": "Flur",
                        "powerMeter": 0,
                        "protected": 0,
                        "temperature": 0,
                        "value": null
                    }
                ],
                "sockets": [
                    {
                        "hostname": "fernseher",
                        "icon": "mdi-television",
                        "metrics": {
                            "energy_daily": "0.392",
                            "power": [
                                [
                                    "time",
                                    "power"
                                ]
                            ]
                        },
                        "name": "Fernseher",
                        "power": "96",
                        "powerMeter": 1,
                        "powerState": "1",
                        "protected": 0,
                        "value": null
                    },
                    {
                        "hostname": "computer",
                        "icon": "mdi-laptop",
                        "metrics": {
                            "energy_daily": "0.742",
                            "power": [
                                [
                                    "time",
                                    "power"
                                ]
                            ]
                        },
                        "name": "Computer",
                        "power": "70",
                        "powerMeter": 1,
                        "powerState": "1",
                        "protected": 0,
                        "value": null
                    },
                    {
                        "hostname": "bar",
                        "icon": "mdi-glass-mug-variant",
                        "metrics": {
                            "energy_daily": "0.024",
                            "power": [
                                [
                                    "time",
                                    "power"
                                ]
                            ]
                        },
                        "name": "Bar",
                        "power": "51",
                        "powerMeter": 1,
                        "powerState": "1",
                        "protected": 0,
                        "value": null
                    },
                    {
                        "hostname": "wohnzimmer-lampe",
                        "icon": "mdi-lightbulb",
                        "metrics": {
                            "power": [
                                [
                                    "time",
                                    "power"
                                ]
                            ]
                        },
                        "name": "Ecklampe",
                        "powerMeter": 0,
                        "powerState": "0",
                        "protected": 0,
                        "value": null
                    },
                    {
                        "hostname": "werkbank",
                        "icon": "mdi-wrench",
                        "metrics": {
                            "energy_daily": "0.132",
                            "power": [
                                [
                                    "time",
                                    "power"
                                ]
                            ]
                        },
                        "name": "Werkbank",
                        "power": "18",
                        "powerMeter": 1,
                        "powerState": "1",
                        "protected": 0,
                        "value": null
                    },
                    {
                        "hostname": "3dDrucker",
                        "icon": "mdi-printer-3d-nozzle",
                        "metrics": {
                            "energy_daily": "0",
                            "power": [
                                [
                                    "time",
                                    "power"
                                ]
                            ]
                        },
                        "name": "3dDrucker",
                        "power": "0",
                        "powerMeter": 1,
                        "powerState": "0",
                        "protected": 0,
                        "value": null
                    },
                    {
                        "hostname": "grill",
                        "icon": "mdi-grill",
                        "metrics": {
                            "energy_daily": "0.669",
                            "power": [
                                [
                                    "time",
                                    "power"
                                ]
                            ]
                        },
                        "name": "Grill",
                        "power": "0",
                        "powerMeter": 1,
                        "powerState": "1",
                        "protected": 0,
                        "value": null
                    },
                    {
                        "hostname": "kueche",
                        "icon": "mdi-hand-wash",
                        "metrics": {
                            "power": [
                                [
                                    "time",
                                    "power"
                                ]
                            ]
                        },
                        "name": "Küche",
                        "powerMeter": 0,
                        "powerState": "0",
                        "protected": 0,
                        "value": null
                    },
                    {
                        "hostname": "server",
                        "icon": "mdi-server",
                        "metrics": {
                            "power": [
                                [
                                    "time",
                                    "power"
                                ]
                            ]
                        },
                        "name": "Server",
                        "power": "37",
                        "powerMeter": 1,
                        "powerState": "1",
                        "protected": 1,
                        "value": null
                    },
                    {
                        "hostname": "kuehlschrank",
                        "icon": "mdi-pizza",
                        "metrics": {
                            "energy_daily": "0.291",
                            "power": [
                                [
                                    "time",
                                    "power"
                                ]
                            ]
                        },
                        "name": "Kühlschrank",
                        "power": "1",
                        "powerMeter": 1,
                        "powerState": "1",
                        "protected": 1,
                        "value": null
                    },
                    {
                        "hostname": "sla-drucker",
                        "icon": "mdi-printer-3d",
                        "metrics": {
                            "energy_daily": "0",
                            "power": [
                                [
                                    "time",
                                    "power"
                                ]
                            ]
                        },
                        "name": "sla-drucker",
                        "power": "0",
                        "powerMeter": 1,
                        "powerState": "0",
                        "protected": 0,
                        "value": null
                    }
                ]
            },
            update: false,
            counter:      
            {
                meterInput: {
                absWarm: null,
                absKalt: null,
                absHeizung: null,
                absStrom: null,
                relHeizung: null,
                relKalt: null,
                relStrom: null,
                relWarm: null,
              },
        }},
        email: null,
        token: null,
        exp: null,
        tokenDecoded:null
    
    },
    charts: {
        pieChartOptions: {
          responsive: true,
          maintainAspectRatio: false,
          plugins: {
            legend: {
              display: true,
              position: "right",
              maxHeight: "auto",
              title: {
                display: true,
                text: "kWh in %",
              },
            },
            datalabels: {
              color: "white",
              textAlign: "center",
  
              font: {
                //weight: "bold",
                size: 12,
              },
            },
          },
        },
        lineChartOptions: {
          responsive: true,
  
          maintainAspectRatio: false,
          animations: {
            radius: {
              duration: 400,
              easing: 'linear',
  
            }
          },
          plugins: {
            legend: {
              labels: {
                usePointStyle: true,
              }
            },
            datalabels: {
              color: "white",
              textAlign: "center",
              font: {
                //weight: "bold",
                size: 0,
              },
            },
          },
          scales: {
            A: {
              display: true,
              drawTicks: false,
              position: 'left',
            },
            B: {
              display: true,
              drawTicks: false,
              position: 'left',
            },
            C: {
              display: true,
              drawTicks: false,
              position: 'right',
            }
          }
        },
      },
    mutations: {
        setEmail(state, email) {
            state.email = email;
        },
        setToken(state, token) {
            state.token = token;
        },
    },
    actions: {},
    getters: {
        isLoggedIn(state) {
            return !!state.token;
        },
    },

}
)