<template >
  <v-app
    v-touch="{
      left: () => swipe('Left'),
      right: () => swipe('Right'),
    }"
  >
    <div v-if="isLoggedIn">
      <v-navigation-drawer v-model="drawer" absolute temporary>
        <v-list-item>
          <v-img :src="logo" max-width="75" class="drawer-image"></v-img>
        </v-list-item>
        <v-divider></v-divider>
        <v-list dense nav>
          <v-list-item
            v-for="item in drawerItems"
            :to="item.to"
            :key="item.title"
            link
          >
            <v-list-item-icon>
              <v-icon>{{ item.icon }}</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title>{{
                $t(`routing.${item.title}`)
              }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-navigation-drawer>

      <v-app-bar
        app
        dark
        class="main-app-bar"
        :src="navBgImage"
        shrink-on-scroll
        prominent
      >
        <v-app-bar-nav-icon @click="drawer = !drawer"></v-app-bar-nav-icon>

        <v-app-bar-title>{{ appTitle }}</v-app-bar-title>
        <v-spacer></v-spacer>


        <v-btn icon>
          <LocaleChanger @isResize="resizeSlider" />
        </v-btn>
        <v-btn icon @click="logout()">
          <v-icon> mdi-logout </v-icon>
        </v-btn>
        <v-btn
          icon
          @click="$vuetify.theme.dark = !$vuetify.theme.dark"
          color="white"
          :title="$t(($vuetify.theme.dark ? 'light' : 'dark') + 'Mode')"
        >
          <v-icon
            :style="{
              transform: `rotate(${$vuetify.theme.dark ? 0 : -180}deg)`,
            }"
            @click="storeToCache()"
            >mdi-theme-light-dark</v-icon
          >
        </v-btn>
        <v-btn style="margin-top: 7px" fab x-small color="accent" v-if="$store.state.tokenDecoded" @click="users()">
          {{ $store.state.tokenDecoded.user.substring(0, 1) }}
        </v-btn>
        <template v-slot:extension>
          <v-tabs ref="tabs" centered v-model="tab" show-arrows>
            <v-tab v-for="item in drawerItems" :to="item.to" :key="item.to">{{
              $t(`routing.${item.title}`)
            }}</v-tab>
          </v-tabs>
        </template>
      </v-app-bar>
    </div>
    <!-- Sizes your content based upon application components -->
    <v-main>
      <keep-alive>
        <!-- <keep-alive> saves the state while tab switching  -->
        <transition :name="transitionName" mode="out-in">
          <router-view />
        </transition>
      </keep-alive>
    </v-main>

    <v-footer app padless absolute>
      <v-col class="text-center" cols="12">
        &copy;
        {{ new Date().getFullYear() }} - badcloud - v{{appVersion}}
      </v-col>
      <v-col v-if="this.$store.state.globalData.update">
        update!! plead reload
      </v-col>
    </v-footer>
  </v-app>
</template>

<style lang="scss" scoped>
.drawer-image {
  margin-top: 15px;
  margin-bottom: 15px;
}

.routing-component-wrapper {
  padding: 15px;
}
</style>

<script>
import main_bg from "../assets/background.jpg";
import logo from "../assets/badcloud_log_gruen.svg";
import LocaleChanger from "@/components/LocaleChanger";
import { mapGetters } from "vuex";
import VueJwtDecode from "vue-jwt-decode";
import packageInfo from '../../package.json';

export default {
  name: "MainNavigation",
  components: { LocaleChanger },
  data: () => ({
    appTitle: "badBoard",
    appVersion: packageInfo.version,
    transitionName: "slide-left",
    drawer: false,
    tab: "",
    drawerItems: [
      { title: "Home", icon: "mdi-home", to: "/" },
      { title: "Sensoren", icon: "mdi-thermometer", to: "/sensoren" },
      { title: "Zaehler", icon: "mdi-speedometer", to: "/zaehler" },
      { title: "Chichi", icon: "mdi-robot-vacuum", to: "/chichi" },
      { title: "Flowers", icon: "mdi-flower", to: "/flowers" },
      //{ title: "User", icon: "mdi-account-group", to: "/user" },
      //{ title: "Music", icon: "mdi-music", to: "/music" },
    ],
    navBgImage: main_bg,
    logo: logo,
    browserCache: {
      theme: true,
    },
    misc: {
      snackbarText: "",
      snackbar: false,
      switchValue: "",
      device: "",
      deviceIcon: "",
      hostname: location.host,
      cacheName: "badCache",
    },
  }),
  computed: {
    ...mapGetters(["isLoggedIn"]),
  },
  methods: {
    updateRouter(path) {
      // Gets called upon swipe.
      this.$router.push(path);
    },
    logout() {
      (this.$store.state.token = null),
        window.localStorage.setItem("token", null);
      this.$router.push("/login");
    },
    users() {
      this.$router.push("/users");
    },
    swipe(direction) {
      console.log(direction);
      let position = 0;
      for (let i in this.drawerItems) {
        console.log(i);
        if (this.drawerItems[i].to == this.tab) {
          position = i;
        }
      }

      position = parseInt(position);
      if (direction == "Right")
        if (position > 0) {
          console.log("Right", this.drawerItems[position - 1]);
          this.updateRouter(this.drawerItems[position - 1].to);
        } else {
          console.log("Right", this.drawerItems.at(-1));
          this.updateRouter(this.drawerItems.at(-1).to);
        }
      if (direction == "Left") {
        console.log(this.drawerItems.length - 1, position);
        if (this.drawerItems.length - 1 !== position) {
          console.log("left", this.drawerItems[2], position + 1);
          this.updateRouter(this.drawerItems[position + 1].to);
        } else {
          console.log("left", this.drawerItems[0]);
          this.updateRouter(this.drawerItems[0].to);
        }
      }
    },
    resizeSlider() {
      this.$refs.tabs.onResize(); // Resizes the v-tabs-slider i.e. the slider under the tabs in v-app-bar
    },
    storeToCache: function () {
      console.log(this.browserCache, this.$vuetify.theme.dark);
      this.browserCache.theme = !this.$vuetify.theme.dark;
      console.log(this.browserCache);
      try {
        window.localStorage.setItem(
          this.misc.cacheName,
          JSON.stringify(this.browserCache)
        );
      } catch (e) {
        console.log("cache write failed");
      }
    },
    restoreFromCache: function () {
      try {
        var test = JSON.parse(
          window.localStorage.getItem(this.misc.cacheName, this.browserCache)
        );
        if (test.theme != null) {
          this.browserCache.theme = test.theme;
          console.log(test);

        }
        this.$vuetify.theme.dark = this.browserCache.theme;
      } catch (e) {
        console.log("cache read failed");
      }
      console.log(this.browserCache);
    },
    checkHost: function () {
      this.misc.hostname = this.$store.state.globalData.hostname;
      console.log(this.misc.hostname);
    },
    checkToken: function () {
      var timeNow = Math.floor(new Date().getTime() / 1000);
      //console.log((this.$store.state.exp-timeNow))
      //todo vorher melden wenn token bald abläuft?
      if (this.$store.state.exp <= timeNow) {
        (this.$store.state.token = null),
          window.localStorage.setItem("token", null);
        if (this.$router.history.current["name"] != "Login") {
          this.$router.push("/login");
          console.log("token abgelaufen");
        }
      }
    },
  },
  mounted() {
    document.title = this.appTitle;
  },
  created() {
    this.$store.state.exp = window.localStorage.getItem("exp");
    this.intervalid1 = setInterval(this.checkToken, 5000);
    this.checkHost(),
      (this.$store.state.token = window.localStorage.getItem("token"));

    if (this.$store.state.token) {
      this.$store.state.tokenDecoded = VueJwtDecode.decode(
        this.$store.state.token
      );
    }

    //console.log(this.$store.state.token);
    if (!this.$store.getters.isLoggedIn) this.$router.push("/login");
    this.$router.beforeEach((to, from, next) => {
      //const toDepth = to.path.split("/").length;
      //const fromDepth = from.path.split("/").length;
      if (to.matched.some((record) => record.meta.requiresAuth)) {
        // this route requires auth, check if logged in
        // if not, redirect to login page.
        if (!this.$store.getters.isLoggedIn) {
          console.log("not loggind, redirect");
          next({ name: "Login" });
        } else {
          next(); // go to wherever I'm going
        }
      } else {
        //this.transitionName = toDepth < fromDepth ? "slide-right" : "slide-left";
        next(); // does not require auth, make sure to always call next()!
      }
    });
    this.restoreFromCache();
  },
};
</script>

<style>
/* we need to adjust the following parameters depending on the cover picture */
:root {
  --cover-blur: 2px;
  --cover-brightness-light: 100%;
  --cover-brightness-dark: 40%;
  --hue-rotate: 0deg;
}

::-webkit-scrollbar {
  display: none;
}

.v-application.theme--light
  > .v-application--wrap
  > .main-app-bar
  .v-image__image--cover {
  /* Add the blur effect */
  filter: brightness(var(--cover-brightness-light)) blur(var(--cover-blur))
    hue-rotate(var(--hue-rotate));
  -webkit-filter: brightness(var(--cover-brightness-light))
    blur(var(--cover-blur)) hue-rotate(var(--hue-rotate));
}

.v-application.theme--dark
  > .v-application--wrap
  > .main-app-bar
  .v-image__image--cover {
  filter: brightness(var(--cover-brightness-dark)) blur(var(--cover-blur))
    hue-rotate(var(--hue-rotate));
  -webkit-filter: brightness(var(--cover-brightness-dark))
    blur(var(--cover-blur)) hue-rotate(var(--hue-rotate));
}

.debug-info-footer {
  color: var();
}
</style>
