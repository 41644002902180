import $vuetify from 'vuetify/es5/locale/fr'

export default {
    // vuetify defaults (buttons and text blocks in data-tables etc...)
    $vuetify,

    // custom translations here:
    welcome: 'Bienvenue!',
    darkMode: 'Thème sombre',
    lightMode: 'Thème lumineux',
    routing: {
        "Home": "Home",
        "Example": 'Exemple'
    }
}
