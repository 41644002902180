<template>
  <div>
    <div>
      <v-row class="flex justify-center" style="margin-left: 2px">
        <v-col
          v-for="(item, index) in devices.flowers"
          :key="item.name"
          :cols="computedCols"
        >
          <v-hover v-slot="{ hover }">
            <v-card
              style="margin-top: 20px"
              :elevation="hover ? 12 : 4"
              @click="flowerDialog(item, index)"
            >
              <v-card-title class="flex justify-center">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <h4 v-bind="attrs" v-on="on">{{ item.name }}</h4>
                  </template>
                  <span>{{ item.hostname }}</span>
                </v-tooltip>
                <v-list-item-icon>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <div v-if="item.battery > 80.0">
                        <v-icon v-bind="attrs" v-on="on"
                          >{{ batteryIcons[0] }}
                        </v-icon>
                      </div>
                      <div v-else-if="item.battery > 60">
                        <v-icon v-bind="attrs" v-on="on">{{
                          batteryIcons[1]
                        }}</v-icon>
                      </div>
                      <div v-else-if="item.battery > 40">
                        <v-icon v-bind="attrs" v-on="on">{{
                          batteryIcons[2]
                        }}</v-icon>
                      </div>
                      <div v-else-if="item.battery > 20">
                        <v-icon v-bind="attrs" v-on="on">{{
                          batteryIcons[3]
                        }}</v-icon>
                      </div>
                      <div v-else>
                        <v-icon v-bind="attrs" v-on="on">{{
                          batteryIcons[4]
                        }}</v-icon>
                      </div>
                    </template>
                    <span>{{ item.battery }} %</span>
                  </v-tooltip>
                  <div v-if="item.moisture < 10">
                    <v-icon>mdi-water-outline</v-icon>
                  </div>
                </v-list-item-icon>
              </v-card-title>

              <v-col>
                <div>
                  {{ $t("flowers.moisture") }} {{ item.value.moisture[0] }}-{{
                    item.value.moisture[1]
                  }}
                  <v-progress-linear
                    color="light-blue"
                    height="20"
                    :value="item.moisture"
                    striped
                    rounded
                  >
                    <strong>{{ Math.ceil(item.moisture) }} %</strong>
                  </v-progress-linear>
                  <br />
                  {{ $t("flowers.temperature") }}
                  {{ item.value.temperature[0] }}-{{
                    item.value.temperature[1]
                  }}
                  <v-progress-linear
                    color="light-green darken-4"
                    height="20"
                    :value="item.temperature"
                    striped
                    rounded
                  >
                    <strong>{{ Math.ceil(item.temperature) }} °C</strong>
                  </v-progress-linear>
                  <br />
                  {{ $t("flowers.light") }} {{ item.value.light[0] }}-{{
                    item.value.light[1]
                  }}
                  <v-progress-linear
                    height="20"
                    :value="item.light"
                    striped
                    rounded
                    color="lime"
                  >
                    <strong>{{ Math.ceil(item.light) }} lux</strong>
                  </v-progress-linear>
                  <br />
                  {{ $t("flowers.conductivity") }}
                  {{ item.value.conductivity[0] }}-{{
                    item.value.conductivity[1]
                  }}
                  <v-progress-linear
                    :value="item.conductivity"
                    height="20"
                    striped
                    rounded
                    color="deep-orange"
                  >
                    <strong>{{ Math.ceil(item.conductivity) }} uS/cm</strong>
                  </v-progress-linear>
                </div>
              </v-col>
            </v-card>
          </v-hover>
        </v-col>
      </v-row>
      <v-dialog v-model="dialogFlower" max-width="780">
        <v-card style="margin-top: 20px">
          <v-layout>
            <v-flex style="height: 20px">
              <v-avatar style="top: -20px" color="indigo">
                <v-icon>{{ misc["deviceIcon"] }}</v-icon>
              </v-avatar>
            </v-flex>
          </v-layout>
          <v-card-title class="justify-center">{{
            misc.deviceName
          }}</v-card-title>
          <v-card-subtitle>{{ this.misc["scientificName"] }}</v-card-subtitle>
          <v-row class="flex justify-center">
            <v-lazy
              v-model="devices.flowers[misc.index]"
              :options="{ threshold: 0.5 }"
              min-height="300"
              transition="fade-transition"
            >
              <div
                v-if="
                  devices.flowers[misc.index]?.metrics &&
                  devices.flowers[misc.index]
                "
              >
                <div v-if="devices.flowers[misc.index].metrics.chartJsEnv">
                  <AreaChart
                    :chart-options="charts.lineChartOptions2axes"
                    type="line"
                    :chart-data="devices.flowers[misc.index].metrics.chartJsEnv"
                    :chart-id="devices.flowers[misc.index].name"
                    dataset-id-key="label"
                    :style="ChartDataStyles"
                  />
                </div>
              </div>
            </v-lazy>
          </v-row>
        </v-card>
      </v-dialog>
    </div>
    <canvas ref="canvas"></canvas>
  </div>
</template>





<script>
import { Line as AreaChart } from "vue-chartjs";
import ChartJSPluginDatalabels from "chartjs-plugin-datalabels";

import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  ArcElement,
  LineElement,
  LinearScale,
  PointElement,
  CategoryScale,
  Filler,
  RadialLinearScale,
} from "chart.js";

ChartJS.register(
  Title,
  Tooltip,
  Legend,
  ArcElement,
  LineElement,
  LinearScale,
  PointElement,
  CategoryScale,
  Filler,
  RadialLinearScale,
  ChartJSPluginDatalabels
);

export default {
  name: "Flowers",
  data: () => ({
    test: "test",
    rws: null,
    devices: { sockets: [], sensors: [], flowers: [], actuators: [] },
    metrics: { sockets: [], sensors: [], flowers: [], actuators: [] },
    menu: false,
    dialogFlower: false,
    batteryIcons: [
      "mdi-battery",
      "mdi-battery-80",
      "mdi-battery-50",
      "mdi-battery-20",
      "mdi-battery-alert-variant-outline",
    ],
    chartType: "AreaChart",
    charts: {
      pieChartOptions: {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          legend: {
            display: true,
            position: "right",
            maxHeight: "auto",
            title: {
              display: true,
              text: "kWh in %",
            },
          },
          datalabels: {
            color: "white",
            textAlign: "center",

            font: {
              //weight: "bold",
              size: 12,
            },
          },
        },
      },
      lineChartOptions: {
        responsive: true,

        maintainAspectRatio: false,
        animations: {
          radius: {
            duration: 400,
            easing: "linear",
          },
        },
        plugins: {
          legend: {
            labels: {
              usePointStyle: true,
            },
          },
          datalabels: {
            color: "white",
            textAlign: "center",
            font: {
              //weight: "bold",
              size: 0,
            },
          },
        },
        scales: {
          A: {
            display: true,
            drawTicks: false,
            position: "left",
          },
        },
      },
      lineChartOptions2axes: {
        responsive: true,

        maintainAspectRatio: false,
        animations: {
          radius: {
            duration: 400,
            easing: "linear",
          },
        },
        plugins: {
          legend: {
            labels: {
              usePointStyle: true,
            },
          },
          datalabels: {
            color: "white",
            textAlign: "center",
            font: {
              //weight: "bold",
              size: 0,
            },
          },
        },
        scales: {
          A: {
            display: true,
            drawTicks: false,
            position: "left",
          },
          B: {
            display: true,
            drawTicks: false,
            position: "right",
          },
        },
      },
    },
    misc: {
      snackbarText: "",
      snackbar: false,
      timeRange: 0,
      hostname: location.host,
      startTime: "12h",
      step: "1h",
    },
  }),
  computed: {
    ChartDataStyles() {
      return {
        height: `${250}px`,
        width: this.computedWidth,
        position: "relative",
      };
    },
    computedCols() {
      if (this.$vuetify.breakpoint.xs) {
        return "12";
      } else if (this.$vuetify.breakpoint.sm) {
        return "6";
      } else if (this.$vuetify.breakpoint.md) {
        return "6";
      } else if (this.$vuetify.breakpoint.lg) {
        return "3";
      } else if (this.$vuetify.breakpoint.xl) {
        return "3";
      } else {
        return "1";
      }
    },
    computedWidth() {
      console.log(this.$vuetify.breakpoint.name);
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return 290;
        case "sm":
          return 450;
        case "md":
          return 450;
        case "lg":
          return 450;
        case "xl":
          return 450;
        default:
          return 450;
      }
    },
  },
  filters: {
    test: function (data) {
      console.log(data);
      return data.replace(".", ",");
    },
  },
  methods: {
    flowerDialog: function (item, index) {
      this.misc["device"] = item.hostname;
      this.misc["deviceName"] = item.name;
      this.misc["deviceIcon"] = item.icon;
      this.misc["scientificName"] = item.value.name;
      this.misc["index"] = index;
      this.misc["value"] = item.value;
      this.misc["temperature"] = item.temperature;
      this.dialogFlower = true;
    },
    fetchDevices() {
      const requestOptions = {
        method: "POST",
        headers: {
          "x-access-token": this.$store.state.token,
          "Content-Type": "application/json",
        },
        body: JSON.stringify("fetchDevices"),
      };

      fetch(
        `${location.protocol}//${this.misc.hostname}/api/devices/get`,
        requestOptions
      )
        .then((response) => response.json())
        .then((data) => {
          this.devices = data;
        })
        .catch((e) => {
          this.misc.snackbarText = "Error " + e;
          this.misc.snackbar = true;
        });
    },

    fetchValues: function () {
      const requestOptions = {
        method: "POST",
        headers: {
          "x-access-token": this.$store.state.token,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(this.devices),
      };

      const url = `${location.protocol}//${this.misc.hostname}/api/device/values/get`;

      fetch(url, requestOptions)
        .then((response) => response.json())
        .then((data) => {
          if (data) {
            this.devices = data;
            if (this.flowerDialog) {
              this.fetchFlowerMetrics();
            }
          }
        })
        .catch((e) => {
          this.misc.snackbarText = "Error " + e;
          this.misc.snackbar = true;
        });
    },

    fetchFlowerMetrics() {
      const requestOptions = {
        method: "POST",
        headers: {
          "x-access-token": this.$store.state.token,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          devices: this.devices,
          startTime: this.misc.startTime,
          step: this.misc.step,
        }),
      };

      fetch(
        `${location.protocol}//${this.misc.hostname}/api/v2/flowers/metrics/get`,
        requestOptions
      )
        .then((response) => response.json())
        .then((data) => {
          this.devices = this.modifyForChartJs(data);
          this.setChartGradient();
        })
        .catch((e) => {
          this.misc.snackbarText = `Error ${e}`;
          this.misc.snackbar = true;
        });
    },

    timeRangeMeter: function () {
      this.misc.startTime = this.range + "h";
      let calcStep = Math.floor(this.range / 30);
      this.misc.step = calcStep <= 0 ? "15m" : calcStep + "h";
      console.log("asdaaaaaaaaaaaa", this.misc.startTime, this.misc.step);
      this.fetchFlowerMetrics();
    },
    setChartType: function () {
      this.chartType =
        this.chartType == "AreaChart" ? "ColumnChart" : "AreaChart";
    },

    modifyForChartJs: function (metricsDevices) {
      for (let a in metricsDevices) {
        for (const element of metricsDevices[a]) {
          if (element.metrics) {
            // Chart for power
            if (element.metrics.power) {
              // Initialize chart view
              if (!element.metrics.chartJsPower?.datasets[0]) {
                element.metrics.chartJsPower = {
                  datasets: [
                    {
                      ...element.metrics.power.datasets[0],
                      yAxisID: "A",
                      fill: true,
                      tension: 0.5,
                      borderColor: "#FC2525",
                      borderWidth: 1,
                      pointBorderColor: "white",
                      backgroundColor: this.gradientRed,
                      label: "power",
                      pointHoverRadius: 15,
                    },
                  ],
                  labels: element.metrics.power.labels,
                };
              }
            }
            // Chart for temperature and humidity
            if (element.metrics.envData) {
              element.metrics.chartJsEnv = {
                datasets: [
                  {
                    ...element.metrics.envData.datasets[1],
                    yAxisID: "A",
                    fill: true,
                    tension: 0.5,
                    borderColor: "#FC2525",
                    borderWidth: 1,
                    pointBorderColor: "white",
                    backgroundColor: this.gradientRed,
                    label: element.metrics.envData.datasets[1].label,
                    pointHoverRadius: 15,
                  },
                  {
                    ...element.metrics.envData.datasets[0],
                    yAxisID: "B",
                    fill: true,
                    tension: 0.5,
                    borderColor: "#05CBE1",
                    borderWidth: 1,
                    pointBorderColor: "white",
                    backgroundColor: this.gradientBlue,
                    label: element.metrics.envData.datasets[0].label,
                    pointHoverRadius: 15,
                  },
                ],
                labels: element.metrics.envData.labels,
              };
            }
          }
        }
      }
      return metricsDevices;
    },
    setChartGradient: function () {
      this.gradientRed = this.$refs.canvas
        .getContext("2d")
        .createLinearGradient(0, 0, 0, 450);
      this.gradientBlue = this.$refs.canvas
        .getContext("2d")
        .createLinearGradient(0, 0, 0, 450);
      this.gradientYellow = this.$refs.canvas
        .getContext("2d")
        .createLinearGradient(0, 0, 0, 450);
      this.gradientGreen = this.$refs.canvas
        .getContext("2d")
        .createLinearGradient(0, 0, 0, 450);

      this.gradientRed.addColorStop(0.1, "rgba(255, 0,0, 0.5)");
      this.gradientRed.addColorStop(0.5, "rgba(255, 0, 0, 0.25)");
      this.gradientRed.addColorStop(1, "rgba(145, 67, 204, 0.46)");

      this.gradientBlue.addColorStop(0, "rgba(0, 231, 255, 0.9)");
      this.gradientBlue.addColorStop(0.5, "rgba(0, 231, 255, 0.25)");
      this.gradientBlue.addColorStop(1, "rgba(0, 231, 255, 0)");

      this.gradientYellow.addColorStop(0, "rgba(0, 255, 0, 0.9)");
      this.gradientYellow.addColorStop(0.5, "rgba(0, 255, 0, 0.25)");
      this.gradientYellow.addColorStop(1, "rgba(0, 255, 0, 0)");

      this.gradientYellow.addColorStop(0, "rgba(0, 255, 231, 0.9)");
      this.gradientYellow.addColorStop(0.5, "rgba(0, 255, 231, 0.25)");
      this.gradientYellow.addColorStop(1, "rgba(0, 255, 231, 0)");
    },
    checkHost: function () {
      console.log(location.host);
      if (
        location.host == "badboard.badcloud.eu" ||
        location.host == "test.badcloud.eu"
      ) {
        this.$store.state.globalData.hostname = location.host;
        this.misc.hostname = this.$store.state.globalData.hostname;
      } else {
        this.$store.state.globalData.hostname = "192.168.0.106:7000";
        this.misc.hostname = this.$store.state.globalData.hostname;
      }
      console.log(this.$store.state.globalData.hostname);
    },
  },
  watch: {
    sliderValueWatch: function (a, b) {
      console.log(a, b, this.misc.switchValue);
      //example code
      //this.misc.switchValue = a;
    },
    menu(val) {
      val && setTimeout(() => (this.activePicker = "YEAR"));
    },
    range: function (a, b) {
      console.log(a, b);
      this.timeRangeMeter();
    },
  },
  mounted() {
    if (this.$store.state.token) {
      this.checkHost();
      this.fetchDevices();
      this.setTimeout1 = setTimeout(this.fetchFlowerMetrics, 2000);
      this.intervalid1 = setInterval(this.fetchFlowerMetrics, 15000);
      this.intervalid1 = setInterval(this.fetchValues, 20000);
    }
  },
  components: {
    AreaChart,
  },
};
</script>




