<template>
  <div>

    <v-divider style="margin: 10px"></v-divider>

    <h2>Sensoren</h2>
    <v-row class="flex justify-center" style="margin-left: 2px">
      <v-col v-for="(item, index) in devices.sensors" :key="item.name" :cols="computedCols">
        <v-hover v-slot="{ hover }">
          <v-card :width="computedWidth" style="margin-top: 20px" :elevation="hover ? 12 : 4"
            @click="sensorDialog(item, index)">
            <v-layout>
              <v-flex style="height: 20px">
                <v-avatar style="top: -20px" color="indigo">
                  <v-icon>{{ item.icon }}</v-icon>
                </v-avatar>
              </v-flex>
            </v-layout>
            <v-card-title class="flex justify-center">
              <h4>{{ item.name }}</h4>
            </v-card-title>
            <v-card-text class="text-left">
              <v-row>
                <v-col> Temperatur: {{ item.temperature }} °C </v-col>
              </v-row>
              <v-row>
                <v-col> Luftfeuchte: {{ item.humidity }} % </v-col>
              </v-row>
              <v-row>
                <v-col> Luftdruck: {{ item.pressure / 100 }} hPa </v-col>
              </v-row>
              <v-row>
                <v-col> Licht: {{ item.light }} lx </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-hover>
      </v-col>
    </v-row>
    <v-spacer></v-spacer>

    <v-divider style="margin: 10px"></v-divider>
    <h2>Rollos</h2>
    <v-row class="flex justify-center" style="margin-left: 2px">
      <v-col v-for="item in devices.actuators" :key="item.name" :cols="computedCols">
        <v-hover v-slot="{ hover }">
          <v-card :width="computedWidth" height="240px" :elevation="hover ? 12 : 4" style="margin-top: 20px"
            class="mx-auto">
            <v-layout>
              <v-flex style="height: 20px">
                <v-avatar style="top: -20px" color="indigo">
                  <v-icon>{{ item.icon }}</v-icon>
                </v-avatar>
              </v-flex>
            </v-layout>
            <v-card-title class="flex justify-center">
              <h4>{{ item.name }}</h4>
            </v-card-title>
            <v-card-text class="text-left">
              <v-row>
                <v-col> Status: {{ item.value }} % </v-col>
              </v-row>
            </v-card-text>
            <v-slider dense hint="shade" max="100" min="0" :value="item.value"
              @end="switchDevices(item.hostname, $event)" step="5" thumb-label ticks></v-slider>
          </v-card>
        </v-hover>
      </v-col>
    </v-row>
    <v-spacer style="margin: 20px"></v-spacer>
    <v-divider style="margin: 10px"></v-divider>
    <h2>Steckdosen</h2>

    <v-row class="flex justify-center" style="margin-left: 2px">
      <v-col v-for="(item, index) in filterPhases" :key="item.name" :cols="computedCols">
        <v-hover v-slot="{ hover }">
          <v-card :width="computedWidth" height="240px" :elevation="hover ? 12 : 4" style="margin-top: 20px"
            :class="item.powerState == 0 ? 'background' : 'light-green mx-xl'" @click="
              socketDialog(
                item.hostname,
                item.icon,
                item.name,
                index,
                item.protected,
                item.power
              )
            ">
            <v-layout>
              <v-flex style="height: 20px">
                <v-avatar style="top: -20px" color="indigo">
                  <v-icon>{{ item.icon }}</v-icon>
                </v-avatar>
              </v-flex>
            </v-layout>
            <v-card-title class="flex justify-center">
              <h4>{{ item.name }}</h4>
            </v-card-title>
            <v-card-text class="text-left">
              <v-row>
                <v-col> Leistung: {{ item.power }} W</v-col>
              </v-row>
              <v-row>
                <v-col v-if="item.name != `Solar`">
                  Kosten Heute:
                  {{ (item.todayEnergy * energyCosts).toFixed(2) }}€
                </v-col>
                <v-col v-if="item.name == `Solar`">
                  Ersparnis Heute:
                  {{ (item.todayEnergy * energyCosts).toFixed(2) }}€
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-hover>
      </v-col>
    </v-row>
    <v-row style="margin: 20px" class="flex justify-center">
      <v-card width="85%">
        <v-card-title class="flex justify-center">
          Gesamt Verbrauch
        </v-card-title>
        <v-card-text class="text-left">
          <v-row>
            <v-col>
              <v-row>
                <v-col>
                  <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <div v-bind="attrs" v-on="on">
                  Momentan Verbrauch:
                  {{ todaySummary.totalPower.toFixed(2) / 1000 }} kW
                  </div>
                  
                  </template>
                  <span> dabei sind die nicht messbaren {{ todaySummary.untrackedPower }} W und minus Solar </span>
                  </v-tooltip>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <div v-bind="attrs" v-on="on">
                  Total Verbrauch Heute:
                  {{ todaySummary.totalTodayEnergy.toFixed(2) }} kWh
                </div>
                  
                </template>
                <span> dabei sind die nicht messbaren {{ todaySummary.untrackedEnergy }} Wh</span>
                </v-tooltip>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  Totale Kosten Heute:
                  {{ (todaySummary.totalTodayEnergy * energyCosts).toFixed(2) }}
                  €
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <div v-bind="attrs" v-on="on">
                  Ersparnis durch Solar:
                  {{ todaySummary.percentSaving.toFixed(2) }} %
                </div>
                  
                </template>
                <span> Solar Energy heute {{ todaySummary.totalSolarEnergy }} Wh</span>
                </v-tooltip>
                </v-col>
              </v-row>
              <v-row v-if="todaySummary.totalFeed > 0">
                <v-col>
                  Gerade Verschenkter Strom:
                  {{ todaySummary.totalFeed.toFixed(2) }} W
                </v-col>
              </v-row>
              
            </v-col>
            <v-col>
              <v-row>
                <v-col>
                  <div>
                    <Doughnut :chart-options="charts.pieChartOptions" :chart-data="pieChartData" chart-id="pie-chart"
                      dataset-id-key="label" :style="ChartDataStyles" />
                  </div>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-row>
    <v-spacer></v-spacer>
    <v-divider style="margin: 10px"></v-divider>
    <v-snackbar v-model="misc['snackbar']" :timeout="misc.snackbarTimeout">
      {{ misc["snackbarText"] }}

      <template v-slot:action="{ attrs }">
        <v-btn color="blue" v-bind="attrs" @click="snackbar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
    <v-dialog v-model="dialog" max-width="300">
      <v-card width="300px" height="200px" style="margin-top: 20px">
        <v-layout>
          <v-flex style="height: 20px">
            <v-avatar style="top: -20px" color="indigo">
              <v-icon>{{ misc["deviceIcon"] }}</v-icon>
            </v-avatar>
          </v-flex>
        </v-layout>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogSensor" max-width="780">
      <v-card style="margin-top: 20px">
        <v-layout>
          <v-flex style="height: 20px">
            <v-avatar style="top: -20px" color="indigo">
              <v-icon>{{ misc["deviceIcon"] }}</v-icon>
            </v-avatar>
          </v-flex>
        </v-layout>
        <v-card-title class="justify-center">{{
        misc.deviceName
        }}</v-card-title>
        <v-row class="flex justify-center">
          <v-lazy v-model="devices.sensors[misc.index]" :options="{
            threshold: 0.1,
          }" min-height="300" :min-width="computedWidth" transition="fade-transition">
            <div v-if="devices.sensors[misc.index]?.metrics && devices.sensors[misc.index]">

              <div v-if="devices.sensors[misc.index].metrics.chartJsEnv">

                <AreaChart :chart-options="charts.lineChartOptions2axes" type="line"
                  :chart-data="devices.sensors[misc.index].metrics.chartJsEnv"
                  :chart-id="devices.sensors[misc.index].name" dataset-id-key="label" :style="ChartDataStyles" />

              </div>

            </div>
          </v-lazy>
        </v-row>

        <v-row class="flex justify-center">
          <v-col> Temperatur: {{ misc.temperature }} °C </v-col>
        </v-row>

        <div v-if="
          misc.deviceName == 'Wohnzimmer' ||
          misc.deviceName == 'Arbeitszimmer' ||
          misc.deviceName == 'Flur' ||
          misc.deviceName == 'Bad'
        ">
          <v-spacer style="margin: 10px"></v-spacer>
          <h3>Heizung</h3>
          <v-card-actions class="flex justify-center">
            <round-slider class="justify-center" v-model="misc.value" step="0.1" circleShape="full"
              :startValue="misc.value" min="10" max="30" circleColor="green" start-angle="-30" end-angle="+240"
              :circle-width="12" :progress-width="3" handleSize="+10" :knob-radius="4"></round-slider>
          </v-card-actions>
          <v-btn fab style="top: -60px" rounded icon @click="misc.value--" color="indigo">
            <v-icon> mdi-minus </v-icon>
          </v-btn>
          <v-btn fab style="top: -60px" rounded icon @click="setValue" color="indigo">
            <v-icon> mdi-content-save </v-icon>
          </v-btn>
          <v-btn fab style="top: -60px" rounded icon @click="misc.value++" color="indigo">
            <v-icon> mdi-plus </v-icon>
          </v-btn>
          <v-expansion-panels>
            <v-expansion-panel>
              <v-expansion-panel-header> Timer </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-card v-model="timerDialog">
                  <v-row class="flex justify-center" style="margin-left: 2px">
                    <v-col v-for="item in timer" :key="item.ID">
                      <div v-if="item.hostname">
                        <v-toolbar dense v-if="item.hostname == misc.device">
                          <v-menu ref="menu" :close-on-content-click="false" :nudge-right="40"
                            :return-value.sync="item.startTime" transition="scale-transition" offset-y max-width="290px"
                            min-width="290px">
                            <template v-slot:activator="{ on, attrs }">
                              <v-text-field v-model="item.startTime" label="start" readonly v-bind="attrs" v-on="on"
                                style="width: 150px"></v-text-field>
                            </template>
                            <v-time-picker v-model="item.startTime" full-width format="24hr" @click:minute="
                              $refs.menu[item.timerId - 1].save(
                                item.startTime
                              )
                            "></v-time-picker>
                          </v-menu>

                          <v-divider vertical></v-divider>
                          <v-menu ref="menu2" :close-on-content-click="false" :nudge-right="40"
                            :return-value.sync="item.endTime" transition="scale-transition" offset-y max-width="290px"
                            min-width="290px">
                            <template v-slot:activator="{ on, attrs }">
                              <v-text-field v-model="item.endTime" label="end" readonly v-bind="attrs" v-on="on"
                                style="width: 150px"></v-text-field>
                            </template>
                            <v-time-picker v-model="item.endTime" full-width format="24hr" @click:minute="
                              $refs.menu2[item.timerId - 1].save(item.endTime)
                            "></v-time-picker>
                          </v-menu>

                          <v-divider vertical></v-divider>

                          <v-spacer></v-spacer>

                          <v-btn-toggle v-model="item.weekdays" color="primary" dense group multiple>
                            <v-btn> Mo </v-btn>
                            <v-btn> Di </v-btn>
                            <v-btn> Mi </v-btn>
                            <v-btn> Do </v-btn>
                            <v-btn> Fr </v-btn>
                            <v-btn> Sa </v-btn>
                            <v-btn> So </v-btn>
                          </v-btn-toggle>
                          <v-spacer></v-spacer>
                          <v-text-field v-model="item.value" label="Temp" offset-y style="width: 20px"></v-text-field>

                          <v-checkbox v-model="item.active"></v-checkbox>
                        </v-toolbar>
                      </div>
                    </v-col>
                  </v-row>
                  <v-card-actions class="justify-center">
                    <v-btn color="green darken-1" @click="addTimer(misc.device, misc.deviceName)">
                      add Timer
                    </v-btn>
                    <v-btn color="green darken-1" @click="setTimer(misc.deviceName)">
                      Speichern
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </div>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogSocket" max-width="780">
      <v-card style="margin-top: 20px">
        <v-layout>
          <v-flex style="height: 20px">
            <v-avatar style="top: -20px" color="indigo">
              <v-icon>{{ misc["deviceIcon"] }}</v-icon>
            </v-avatar>
          </v-flex>
        </v-layout>
        <v-card-title class="justify-center">{{
        misc.deviceName
        }}</v-card-title>
        <v-row class="flex justify-center" v-if="devices.sockets[misc.index]">
          <v-lazy v-if="devices.sockets[misc.index].powerMeter == 1" v-model="devices.sockets[misc.index]" :options="{
            threshold: 0.1,
          }" min-height="300" :min-width="computedWidth" transition="fade-transition">
            <div v-if="devices.sockets[misc.index].metrics">

              <div v-if="devices.sockets[misc.index].metrics.chartJsPower">

                <AreaChart :chart-options="charts.lineChartOptions" type="line"
                  :chart-data="devices.sockets[misc.index].metrics.chartJsPower"
                  :chart-id="devices.sockets[misc.index].name" dataset-id-key="label" :style="ChartDataStyles" />

              </div>

            </div>
          </v-lazy>
        </v-row>
        <div>
          <v-card-title class="justify-center">Gerät ein- oder ausschalten ?</v-card-title>
          <v-card-actions class="justify-center">
            <v-spacer></v-spacer>
            <v-btn color="green darken-1" @click="switchDevices(misc['device'], 'On')">
              Einschalten
            </v-btn>
            <v-btn :disabled="
              misc.protected == 1 ||
              misc.deviceName == 'server' ||
              (misc.deviceName == '3dDrucker' && misc.power > 14) ||
              (misc.deviceName == 'sla-drucker' && misc.power > 11)
            " color="green darken-1" @click="switchDevices(misc['device'], 'Off')">
              Ausschalten
            </v-btn>
            <v-spacer></v-spacer>
          </v-card-actions>
        </div>
      </v-card>
    </v-dialog>
    <div class="routing-component-wrapper flex justify-center">
      <v-row class="flex justify-center">
        <v-col width="100%" cols="auto" class="flex justify-center">
          <v-card class="flex justify-center">
            <v-color-picker v-model="misc.picker" mode="rgba" elevation="15" canvas-height="300"
              :width="computedWidth * 1.5"></v-color-picker>
            <v-btn-toggle class="flex justify-center">
              <v-btn @click="sendLed({'effect':'Off'})"> OFF </v-btn>
              <v-btn @click="sendLed({'effect':'Solid'})"> Solid </v-btn>
              <v-btn @click="sendLed({'effect':'Aurora'})"> Aurora </v-btn>
              <v-btn @click="sendLed({'effect':'Dissolve'})"> Dissolve </v-btn>
              <v-btn @click="sendLed({'effect':'Random'})"> Random </v-btn>
            </v-btn-toggle>
          </v-card>
        </v-col>
      </v-row>
    </div>
    <canvas ref="canvas"></canvas>
  </div>
</template>





<script>

import 'chart.js/auto';
import RoundSlider from "vue-round-slider";
import { Doughnut, Line as AreaChart } from "vue-chartjs/legacy";
import ChartJSPluginDatalabels from "chartjs-plugin-datalabels";
import axios from "axios";

import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  ArcElement,
  LineElement,
  LinearScale,
  PointElement,
  CategoryScale,
  Filler,
  RadialLinearScale,
} from "chart.js";

ChartJS.register(
  Title,
  Tooltip,
  Legend,
  ArcElement,
  LineElement,
  LinearScale,
  PointElement,
  CategoryScale,
  Filler,
  RadialLinearScale,
  ChartJSPluginDatalabels
);

export default {
  name: "Home",
  data: () => ({
    gradientRed: null,
    gradientBlue: null,
    gradientYellow: null,
    gradientGreen:null,
    test: "test",
    rws: null,
    devices: { sockets: [], sensors: [], actuators: [] },
    todaySummary: {
      untrackedPower: 43,
      untrackedEnergy: 0,
      totalFeed: 0,
      percentSaving: 0,
      totalSolarEnergy: 0,
      totalTodayEnergy: 0.0,
      totalTodayEnergyData: {
        labels: [],
        datasets: [
          {
            data: [],
            borderWidth: 0,
            backgroundColor: [
              "#0074D9",
              "#FF4136",
              "#2ECC40",
              "#FF851B",
              "#7FDBFF",
              "#B10DC9",
              "#FFDC00",
              "#001f3f",
              "#39CCCC",
              "#01FF70",
              "#85144b",
              "#F012BE",
              "#3D9970",
              "#111111",
              "#AAAAAA",
            ],
            hoverOffset: 8
          },
        ],
      },
      totalPower: 0.0,
    },
    energyCosts: 0.35,
    timer: null,
    values: {},
    dialog: false,
    dialogSensor: false,
    dialogSocket: false,
    timerDialog: false,
    menu2: false,
    modal2: false,
    time: null,
    sliderValueWatch: "",

    charts: {
      pieChartOptions: {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          legend: {
            display: true,
            position: "right",
            maxHeight: "auto",
            title: {
              display: true,
              text: "kWh in %",
            },
          },
          datalabels: {
            color: "white",
            textAlign: "center",

            font: {
              //weight: "bold",
              size: 12,
            },
          },
        },
      },
      lineChartOptions: {
        responsive: true,

        maintainAspectRatio: false,
        animations: {
          radius: {
            duration: 400,
            easing: 'linear',

          }
        },
        plugins: {
          legend: {
            labels: {
              usePointStyle: true,
            }
          },
          datalabels: {
            color: "white",
            textAlign: "center",
            font: {
              //weight: "bold",
              size: 0,
            },
          },
        },
        scales: {
          A: {
            display: true,
            drawTicks: false,
            position: 'left',
          }
        }
      },
      lineChartOptions2axes: {
        responsive: true,

        maintainAspectRatio: false,
        animations: {
          radius: {
            duration: 400,
            easing: 'linear',

          }
        },
        plugins: {
          legend: {
            labels: {
              usePointStyle: true,
            }
          },
          datalabels: {
            color: "white",
            textAlign: "center",
            font: {
              //weight: "bold",
              size: 0,
            },
          },
        },
        scales: {
          A: {
            display: true,
            drawTicks: false,
            position: 'left',
          },
          B: {
            display: true,
            drawTicks: false,
            position: 'right',
          }
        }
      },
    },
    misc: {
      snackbarText: "",
      snackbar: false,
      snackbarTimeout: 10000,
      switchValue: 0,
      device: "",
      deviceName: "",
      deviceIcon: "",
      power: "",
      value: null,
      picker: null,
      temperature: "",
      protected: 0,
      index: 0,
      hostname: location.host,
    },
  }),
  computed: {
    lineChartData() {
      return this.devices.sockets[this.misc.index].metrics.chartJsPower;
    },
    pieChartData() {
      return this.todaySummary.totalTodayEnergyData;
    },
    chartOptions() {
      return this.charts.pieChartOptions;
    },
    filterPhases() {
      let devices = this.devices.sockets.filter(
        (device) => !device.hostname.includes("herd-l")
      );
      return devices;
    },
    ChartDataStyles() {
      return {
        height: `${250}px`,
        width: this.computedWidth,
        position: "relative",
      };
    },
    computedCols() {
      if (this.$vuetify.breakpoint.xs) {
        return "6";
      } else if (this.$vuetify.breakpoint.sm) {
        return "4";
      } else if (this.$vuetify.breakpoint.md) {
        return "2";
      } else if (this.$vuetify.breakpoint.lg) {
        return "2";
      } else if (this.$vuetify.breakpoint.xl) {
        return "2";
      } else {
        return "1";
      }
    },
    computedWidth() {
      console.log(this.$vuetify.breakpoint.name);
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return 190;
        case "sm":
          return 230;
        case "md":
          return 250;
        case "lg":
          return 350;
        case "xl":
          return 350;
        default:
          return 350;
      }
    },
  },
  methods: {
    addTimer: function (device, deviceName) {
      /*
      .... muss verbessert werden, das system funktioniert nur , wenn die timerId auch in der richten reihenfolge in der 
      Datenbank stehen
      
      */

      let id = [];
      let timerId = [];
      let coolTime = 1;
      let heatTime = 2;

      /* find missing number bsp:
      let a = [5, 7],
        count = 10,
        missing = []

      for (let i = 1; i <= count; i++) {
        if (a.indexOf(i) === -1) {
          missing.push(i)
        }
      }

      */

      for (let i of this.timer) {

        id.push(i.ID);
        if (i.name == deviceName) {
          coolTime = i.coolTime;
          heatTime = i.heatTime;
          timerId.push(i.timerId);
        }
      }
      id.sort();
      timerId.sort();
      let newID = id.at(-1) + 1;
      let newTimerId = timerId.at(-1) + 1;
      if (!newTimerId) {
        newTimerId = 1;
      }
      console.log(newTimerId);

      let defaultValues = {
        ID: newID,
        active: 1,
        coolTime: coolTime,
        endTime: null,
        heatTime: heatTime,
        hostname: device,
        timerId: newTimerId,
        name: deviceName,
        startTime: null,
        value: 15,
        weekdays: [],
      };
      this.timer.push(defaultValues);
    },
    setTimer: function (deviceName) {
      console.log(deviceName);
      console.log(this.timer);

      const requestOptions = {
        method: "POST",
        headers: {
          "x-access-token": this.$store.state.token,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(this.timer),
      };
      fetch(
        location.protocol + "//" + this.misc.hostname + "/api/timer/set",
        requestOptions
      )
        .then((response) => response.json())
        .then((data) => {
          console.log(data);
          this.fetchTimer();
        })
        .catch((e) => {
          this.misc["snackbarText"] = "Error " + e;
          this.misc["snackbar"] = true;
        });
    },
    setValue: function () {
      console.log({
        hostname: this.misc.device,
        type: "temperature",
        value: this.misc.value,
      });
      const requestOptions = {
        method: "POST",
        headers: {
          "x-access-token": this.$store.state.token,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          hostname: this.misc.device,
          type: "temperature",
          value: this.misc.value,
        }),
      };
      fetch(
        location.protocol + "//" + this.misc.hostname + "/api/value/set",
        requestOptions
      )
        .then((response) => response.json())
        .then((data) => {
          console.log(data)
          this.fetchDevices();
          this.misc["snackbarText"] = "gespeichert";
          this.misc["snackbar"] = true;
        })
        .catch((e) => {
          this.misc["snackbarText"] = "Error " + e;
          this.misc["snackbar"] = true;
        });
    },
    fetchDevices: function () {
      const requestOptions = {
        method: "POST",
        headers: {
          "x-access-token": this.$store.state.token,
          "Content-Type": "application/json",
        },
        body: JSON.stringify("fetchDevices"),
      };
      fetch(
        location.protocol + "//" + this.misc.hostname + "/api/devices/get",
        requestOptions
      )
        .then((response) => response.json())
        .then((data) => {
          if (data.message != "TokenIsInvalid") {
            this.devices = data;
            this.fetchValues();
          } else {
            console.log("TOOOOKEN TEST KAPUTT", data);
            (this.$store.state.token = null); 
            this.$router.push("/login");
          }
        })
        .catch((e) => {
          this.misc["snackbarText"] = "Error " + e;
          this.misc["snackbar"] = true;
        });
    },
    fetchTimer: function () {
      const requestOptions = {
        method: "POST",
        headers: {
          "x-access-token": this.$store.state.token,
          "Content-Type": "application/json",
        },
        body: JSON.stringify("fetchTimer"),
      };
      fetch(
        location.protocol + "//" + this.misc.hostname + "/api/timer/get",
        requestOptions
      )
        .then((response) => response.json())
        .then((data) => {
          this.timer = data.heater;
        })
        .catch((e) => {
          this.misc["snackbarText"] = "Error " + e;
          this.misc["snackbar"] = true;
        });
    },
/*
    sumHerd: function (Data) {
      var sumPower = 0.0;
      var sumTotalEnergy = 0.0;
      var indexToDelete = [];
      var herdIndex = "";

      for (var i = 0; i < Data.sockets.length; i++) {
        if (Data.sockets[i].hostname.includes("herd-l")) {
          sumPower = parseFloat(sumPower + parseFloat(Data.sockets[i].power));
          sumTotalEnergy = parseFloat(
            sumTotalEnergy + Data.sockets[i].todayEnergy
          );
          indexToDelete.push(i);
        }
      }

      var sumHerd = {
        hostname: "herd",
        icon: "mdi-stove",
        name: "Herd",
        power: sumPower,
        powerMeter: 1,
        metrics: {},
        powerState: "1",
        protected: 1,
        todayEnergy: sumTotalEnergy,
        value: null,
      };

      //reverse loop, to prevent, delte index element and change length

      //mergen ---------------------------------------------zu einem array

      /*console.log(indexToDelete)
      for (var a = indexToDelete.length - 1; a >= 0; a--) {
      Data.sockets.splice(indexToDelete[a], 1)
        if (Data.sockets[indexToDelete[a]].metrics){
          var powerPhase = "powerl"+(a+1)
          console.log(Data.sockets[indexToDelete[a]].metrics)
          sumHerd.metrics[powerPhase] =  Data.sockets[indexToDelete[a]].metrics.power
        }
      }
      *//*
      //}
      //console.log(sumHerd.metrics)
      //console.log(Data.sockets)
      for (var b = 0; b < Data.sockets.length; b++) {
        if (Data.sockets[b].hostname == "herd") {
          herdIndex = b;
        }
      }

      if (typeof herdIndex == "number") {
        Data.sockets[herdIndex] = sumHerd;
      } else {
        Data.sockets.push(sumHerd);
      }

      return Data;
    },
    */
    calcSummary: function () {
      this.todaySummary.totalTodayEnergyData.datasets[0].data = [];
      this.todaySummary.totalTodayEnergyData.labels = [];
      this.todaySummary.totalTodayEnergy = 0.0;
      this.todaySummary.totalPower = 0.0;
      this.todaySummary.totalFeed = 0.0;

      for (const element of this.devices.sockets) {
        if (element.todayEnergy) {
          //exclude herd-l.*
          if (!element.hostname.includes("herd-l") && !element.hostname.includes("solar")) {
            //sum energy and add labels
            this.todaySummary.totalTodayEnergy =
              this.todaySummary.totalTodayEnergy +
              parseFloat(element.todayEnergy);
            this.todaySummary.totalTodayEnergyData.datasets[0].data.push(
              parseFloat(element.todayEnergy)
            );
              this.todaySummary.totalTodayEnergyData.labels.push(
                element.name
              );
            //sum current power
            this.todaySummary.totalPower =
              this.todaySummary.totalPower +
              parseFloat(element.power);
          }
        }
      }

      //add untracked power consumption
      //calculate round about wh
      let today = new Date();
      let h = today.getHours();
      let m = today.getMinutes();

      let untracked_power = this.todaySummary.untrackedPower 
      this.todaySummary.untrackedEnergy = parseFloat(((untracked_power * (h+m/60)) / 1000).toFixed(2))
      
      
      this.todaySummary.totalTodayEnergy = this.todaySummary.totalTodayEnergy + this.todaySummary.untrackedEnergy
      this.todaySummary.totalPower = this.todaySummary.totalPower + untracked_power

      
      //include solar panels
      for (const element of this.devices.sockets) {
        if (element.todayEnergy) {
          if (element.hostname.includes("solar")) {
            //sum energy and add labels
            this.todaySummary.totalTodayEnergy =
              this.todaySummary.totalTodayEnergy -
              parseFloat(element.todayEnergy);
            //calculate savings in percent
            this.todaySummary.totalSolarEnergy = parseFloat(element.todayEnergy)

            this.todaySummary.percentSaving =  element.todayEnergy / this.todaySummary.totalTodayEnergy * 100
            
            //check if total energy lower than 0
            if ( this.todaySummary.totalPower < 0 ){
              this.todaySummary.totalFeed = this.todaySummary.totalPower
              this.todaySummary.totalPower = 0
            }
            //sum current power
            this.todaySummary.totalPower =
              this.todaySummary.totalPower -
              parseFloat(element.power);
          }
        }
      }

      //calc percentages
      for (
        let a = 0;
        a < this.todaySummary.totalTodayEnergyData.datasets[0].data.length;
        a++
      ) {
        this.todaySummary.totalTodayEnergyData.datasets[0].data[a] = (
          (this.todaySummary.totalTodayEnergyData.datasets[0].data[a] /
            this.todaySummary.totalTodayEnergy) *
          100
        ).toFixed(2);
      }



      //sort ----------------------------------------
      let { sortedLabels, sortedData } = this.sortTwoArrays(
        this.todaySummary.totalTodayEnergyData.labels,
        this.todaySummary.totalTodayEnergyData.datasets[0].data,
        0.5
      );
      this.todaySummary.totalTodayEnergyData.labels = sortedLabels;
      this.todaySummary.totalTodayEnergyData.datasets[0].data = sortedData;
      //----------------------------------------------

      return this.todaySummary.totalTodayEnergyData;
    },
    sortTwoArrays: function (array1, array2, min) {
      ////sort arrays
      let allData = [];
      for (let i = 0; i < array1.length; ++i) {
        //how in dynamic in funtion?
        if (this.todaySummary.totalTodayEnergyData.datasets[0].data[i] > min) {
          allData.push({
            label: array1[i],
            data: array2[i],
          });
        }
      }
      // Sort them by the data value
      allData.sort((b, a) => a.data - b.data);

      // And split them again
      let sortedLabels = allData.map((e) => e.label);
      let sortedData = allData.map((e) => e.data);

      return { sortedLabels, sortedData };
    },
    //delete metrics object to do not send payload back to server
    reducePayload: function (devices) {
      let localDevices = devices;
      for (let a in localDevices) {
        for (const element of localDevices[a]) {
          if (element.metrics) {
            element.metrics = null;
          }
        }
      }
      return localDevices;
    },
    fetchValues: function () {
      //if a dialog open dont, reduce objects metrics
      //ugly animation, if metrics have no values
      let onlyDevices = this.devices
      if (!this.dialogSensor && !this.dialogSocket) {
        onlyDevices = this.reducePayload(this.devices);
      }


      const requestOptions = {
        method: "POST",
        headers: {
          "x-access-token": this.$store.state.token,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(onlyDevices),
      };
      fetch(
        location.protocol +
        "//" +
        this.misc.hostname +
        "/api/device/values/get",
        requestOptions
      )
        .then((response) => response.json())
        .then((data) => {
          if (data) {
            this.devices = data;
            if (this.dialogSensor || this.dialogSocket) {
              this.fetchDeviceMetrics();
            }

            this.calcSummary();
          }

        })
        .catch((e) => {
          this.misc["snackbarText"] = "Error " + e;
          this.misc["snackbar"] = true;
        });
    },
    //todo 
    calcAverage: function (metricsDevices) {
      let totalMetricsData = 0;
      let countMetricsData = 0;

      for (let device of metricsDevices) {
        for (let data of device) {
          if (data.metrics) {
            for (let metric of data.metrics) {
              totalMetricsData += metric[1];
              countMetricsData++;
            }
          }
        }
      }

      const averageMetricsData = totalMetricsData / countMetricsData;
      return averageMetricsData;
    },
    modifyForChartJs: function(metricsDevices) {
      for (let key in metricsDevices) {
        for (const element of metricsDevices[key]) {
          if (element.metrics) {
            if (element.metrics.power && !element.metrics.chartJsPower?.datasets[0]) {
              element.metrics.chartJsPower = {
                datasets: [],
                labels: [],
              };
              element.metrics.chartJsPower.datasets[0] = Object.assign(
                {
                  data: element.metrics.power.datasets[0].data,
                  yAxisID: 'A',
                  fill: true,
                  tension: 0.5,
                  borderColor: '#FC2525',
                  borderWidth: 1,
                  pointBorderColor: 'white',
                  backgroundColor: this.gradientRed,
                  label: 'power',
                  pointHoverRadius: 15,
                }
              );
              element.metrics.chartJsPower.labels = element.metrics.power.labels;
            }
            if (element.metrics.envData) {
              element.metrics.chartJsEnv = {
                datasets: [],
                labels: [],
              };
              element.metrics.chartJsEnv.datasets[0] = Object.assign(
                {
                  data: element.metrics.envData.datasets[1].data,
                  yAxisID: 'A',
                  fill: true,
                  tension: 0.5,
                  borderColor: '#FC2525',
                  borderWidth: 1,
                  pointBorderColor: 'white',
                  backgroundColor: this.gradientRed,
                  label: 'temp',
                  pointHoverRadius: 15,
                }
              );
              element.metrics.chartJsEnv.datasets[1] = Object.assign(
                {
                  data: element.metrics.envData.datasets[0].data,
                  yAxisID: 'B',
                  fill: true,
                  tension: 0.5,
                  borderColor: '#05CBE1',
                  borderWidth: 1,
                  pointBorderColor: 'white',
                  backgroundColor: this.gradientBlue,
                  label: 'humid',
                  pointHoverRadius: 15,
                }
              );
              element.metrics.chartJsEnv.labels = element.metrics.envData.labels;
            }
          }
        }
      }
      return metricsDevices;
    },
    setChartGradient: function () {

      this.gradientRed = this.$refs.canvas
        .getContext("2d")
        .createLinearGradient(0, 0, 0, 450);
      this.gradientBlue = this.$refs.canvas
        .getContext("2d")
        .createLinearGradient(0, 0, 0, 450);
      this.gradientYellow = this.$refs.canvas
        .getContext("2d")
        .createLinearGradient(0, 0, 0, 450);
      this.gradientGreen = this.$refs.canvas
        .getContext("2d")
        .createLinearGradient(0, 0, 0, 450);


      this.gradientRed.addColorStop(0.1, "rgba(255, 0,0, 0.5)");
      this.gradientRed.addColorStop(0.5, "rgba(255, 0, 0, 0.25)");
      this.gradientRed.addColorStop(1, "rgba(145, 67, 204, 0.46)");


      this.gradientBlue.addColorStop(0, "rgba(0, 231, 255, 0.9)");
      this.gradientBlue.addColorStop(0.5, "rgba(0, 231, 255, 0.25)");
      this.gradientBlue.addColorStop(1, "rgba(0, 231, 255, 0)");

      this.gradientYellow.addColorStop(0, "rgba(0, 255, 0, 0.9)");
      this.gradientYellow.addColorStop(0.5, "rgba(0, 255, 0, 0.25)");
      this.gradientYellow.addColorStop(1, "rgba(0, 255, 0, 0)");

      this.gradientYellow.addColorStop(0, "rgba(0, 255, 231, 0.9)");
      this.gradientYellow.addColorStop(0.5, "rgba(0, 255, 231, 0.25)");
      this.gradientYellow.addColorStop(1, "rgba(0, 255, 231, 0)");


    },
    fetchDeviceMetrics: function () {

      const headers = {
          "x-access-token": this.$store.state.token,
          "Content-Type": "application/json",
        };
      const body = JSON.stringify({
          devices: this.devices,
          startTime: "8h",
          step: "20m",
        });

        axios.post(location.protocol + "//" + this.misc.hostname + "/api/v2/devices/metrics/get", body, { headers })
        .then((response) => response.data)
        .then((data) => {
          //warum trenn ich das überhaupt?
          //this.devices = data;
          //this.sumHerd(data)
          //----------------------------
          this.devices = this.modifyForChartJs(data)
          this.setChartGradient()
        })
        .catch(error => {
          this.misc.snackbarText = "Error " + error.message;
          this.misc.snackbar = true;
          setTimeout(() => {
            this.fetchDevices();
          }, 10000); // 10000 Millisekunden = 10 Sekunden
        });
    },

    switchDevices: function (device, state) {
      const requestOptions = {
        method: "POST",
        headers: {
          "x-access-token": this.$store.state.token,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ hostname: device, state: state }),
      };
      fetch(
        location.protocol + "//" + this.misc.hostname + "/api/devices/switch",
        requestOptions
      )
        .then((response) => response.json())
        //könnte man gleich serverseitig machen und als response das values json holen
        .then((data) => {
          if (data) {
            this.fetchValues();

          }

        })
        .catch((e) => {
          this.misc["snackbarText"] = "Error " + e;
          this.misc["snackbar"] = true;
        });
      (this.dialog = false); 
      (this.dialogSocket = false);
    },
    sensorDialog: function (item, index) {
      //fetch values/metric during dialog
      this.fetchValues()
      this.fetchTimer();
      (this.misc["device"] = item.hostname);
      this.misc["deviceName"] = item.name;
      this.misc["deviceIcon"] = item.icon;
      this.misc["index"] = index;
      this.misc["value"] = item.value;
      this.misc["temperature"] = item.temperature;
      this.dialogSensor = true;
    },
    socketDialog: function (device, icon, deviceName, index, proTected, power) {
      //fetch values/metric during dialog
      this.fetchValues()
      console.log(device, icon, index, proTected, power);
      //todo devices und metrics nicht mehr gleich....index nicht mehr gleich
      //funktioniert nur wenn metrics schon abgeholt wurde

      for (let i = 0; i < this.devices.sockets.length; i++) {
        if (this.devices.sockets[i].hostname == device) {
          index = i;
        }
      }

      this.misc["power"] = power;
      this.misc["protected"] = proTected;
      this.misc["device"] = device;
      this.misc["deviceName"] = deviceName;
      this.misc["deviceIcon"] = icon;
      this.misc["index"] = index;
      this.dialogSocket = true;
    },
    setDefaultValues: function () {
      this.devices = this.$store.state.globalData.metrics;
    },
    checkFlowers: function () {
      let durstig = [];
      for (const element of this.devices.flowers) {
        if (
          element.moisture <
          element.value.moisture[0]
        ) {
          durstig.push(element.name);
        }
      }
      if (durstig.length > 0) {
        if (durstig.length > 1) {
          this.misc["snackbarText"] = durstig + " haben durst";
        } else {
          this.misc["snackbarText"] = durstig + " hat durst";
        }

        this.misc["snackbar"] = true;
      }
    },
    checkHost: function () {
      console.log(location.host);
      if (location.host == "badboard.badcloud.eu" ||  location.host == "test.badcloud.eu") {
        this.$store.state.globalData.hostname = location.host;
        this.misc.hostname = this.$store.state.globalData.hostname;
      } else {
        this.$store.state.globalData.hostname = "192.168.0.106:7000";
        this.misc.hostname = this.$store.state.globalData.hostname;
      }
      console.log(this.$store.state.globalData.hostname);
    },
    sendLed: function (LED) {
      let COMMAND = { method: "LED", command: LED };
      
      const requestOptions = {
        method: "POST",
        headers: {
          "x-access-token": this.$store.state.token,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(COMMAND),
      };
      fetch(
        location.protocol + "//" + this.misc.hostname + "/api/v2/led/set",
        requestOptions
      )
        .then((response) => response.json())
        .catch((e) => {
          this.misc["snackbarText"] = "Error " + e;
          this.misc["snackbar"] = true;
        });
      this.dialog = false;
    },
    parseRgb: function (value) {
      
      let a = value;
      let RGB = {
        color: {
        r : a.rgba["r"],
        g : a.rgba["g"],
        b : a.rgba["b"],
        a : a.rgba["a"]
      }
    }
      this.sendLed(RGB);
    },
    getWttr: function () {
      fetch("https://wttr.in/dresden?format=j1&format=v2&1")
        .then((response) => response.json())

        .then((data) => {
          if (data) {
            console.log(data);
            //example weather code
            //var currentWeahter = data.current_condition[0];
            //var timeWeather = data.weather[0].hourly;
            //console.log(currentWeahter);
            //console.log(timeWeather);
           
          }
        })
        .catch((e) => {
          this.misc["snackbarText"] = "Error " + e;
          this.misc["snackbar"] = true;
        });
    },
  },
  watch: {
    sliderValueWatch: function (a, b) {
      console.log(a, b, this.misc.switchValue);
    },
    "misc.picker": function (a, b) {
      if (a && b) {
        this.parseRgb(a);
      }
    },
  },
  mounted() {
    //this.setDefaultValues(),

    this.checkHost();

    if (this.$store.state.token) {
      this.fetchDevices();
        (this.setTimeout1 = setTimeout(this.fetchDeviceMetrics, 2000));
        //(this.setTimeout2 = setTimeout(this.getWttr, 2000)),
        (this.intervalid1 = setInterval(this.fetchValues, 5000));
        (this.intervalid3 = setInterval(this.checkFlowers, 100000));

    }
  },
  components: {

    RoundSlider,
    Doughnut,
    AreaChart
  },
};
</script>


