<template>
  <div>
    <div>
      <v-row class="flex justify-center" style="margin-left: 2px">
        <v-col :cols="computedCols" @click="timeRangeMeter">
          <h4>Zeitraum</h4>
          <v-card-text>
            <v-slider
              v-model="range"
              thumb-label="always"
              step="1"
              min="2"
              max="168"
            ></v-slider>
            <h4>Die letzen: {{ misc.startTime }}</h4>
            <h4>Punktabstand: {{ misc.step }}</h4>
          </v-card-text>

        </v-col>
        <v-col
          v-for="(item, index) in devices.sensors"
          :key="item.name"
          :cols="computedCols"
        >
          <v-hover v-slot="{ hover }">
            <v-card style="margin-top: 20px" :elevation="hover ? 12 : 4">
              <v-card-title class="flex justify-center">
                <h4>{{ item.name }}</h4>
              </v-card-title>
              <v-card-text class="text-left"> </v-card-text>

              <div
                v-if="
                  devices.sensors[index]?.metrics && devices.sensors[index]
                "
              >
                <div v-if="devices.sensors[index].metrics.chartJsEnv">
                  <AreaChart
                    :chart-options="charts.lineChartOptions2axes"
                    type="line"
                    :chart-data="devices.sensors[index].metrics.chartJsEnv"
                    :chart-id="devices.sensors[index].name"
                    dataset-id-key="label"
                    :style="ChartDataStyles"
                  />
                </div>
              </div>
            </v-card>
          </v-hover>
        </v-col>
      </v-row>
      <v-snackbar v-model="misc['snackbar']" :timeout="misc.snackbarTimeout">
        {{ misc["snackbarText"] }}

        <template v-slot:action="{ attrs }">
          <v-btn color="blue" v-bind="attrs" @click="snackbar = false">
            Close
          </v-btn>
        </template>
      </v-snackbar>
    </div>
    <canvas ref="canvas"></canvas>
  </div>
</template>





<script>
import { Line as AreaChart } from "vue-chartjs";
import ChartJSPluginDatalabels from "chartjs-plugin-datalabels";
import axios from "axios";

import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  ArcElement,
  LineElement,
  LinearScale,
  PointElement,
  CategoryScale,
  Filler,
  RadialLinearScale,
} from "chart.js";

ChartJS.register(
  Title,
  Tooltip,
  Legend,
  ArcElement,
  LineElement,
  LinearScale,
  PointElement,
  CategoryScale,
  Filler,
  RadialLinearScale,
  ChartJSPluginDatalabels
);

export default {
  name: "Zaehler",
  data: () => ({
    test: "test",
    rws: null,
    meter: {},
    devices: { sockets: [], sensors: [], actuators: [] },
    metrics: { sockets: [], sensors: [], actuators: [] },
    menu: false,
    chartType: "AreaChart",
    charts: {
      pieChartOptions: {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          legend: {
            display: true,
            position: "right",
            maxHeight: "auto",
            title: {
              display: true,
              text: "kWh in %",
            },
          },
          datalabels: {
            color: "white",
            textAlign: "center",

            font: {
              //weight: "bold",
              size: 12,
            },
          },
        },
      },
      lineChartOptions: {
        responsive: true,

        maintainAspectRatio: false,
        animations: {
          radius: {
            duration: 400,
            easing: "linear",
          },
        },
        plugins: {
          legend: {
            labels: {
              usePointStyle: true,
            },
          },
          datalabels: {
            color: "white",
            textAlign: "center",
            font: {
              //weight: "bold",
              size: 0,
            },
          },
        },
        scales: {
          A: {
            display: true,
            drawTicks: false,
            position: "left",
          },
        },
      },
      lineChartOptions2axes: {
        responsive: true,

        maintainAspectRatio: false,
        animations: {
          radius: {
            duration: 400,
            easing: "linear",
          },
        },
        plugins: {
          legend: {
            labels: {
              usePointStyle: true,
            },
          },
          datalabels: {
            color: "white",
            textAlign: "center",
            font: {
              //weight: "bold",
              size: 0,
            },
          },
        },
        scales: {
          A: {
            display: true,
            drawTicks: false,
            position: "left",
          },
          B: {
            display: true,
            drawTicks: false,
            position: "right",
          },
        },
      },
    },
    misc: {
      snackbarText: "",
      snackbar: false,
      timeRange: 0,
      hostname: location.host,
      startTime: "2h",
      step: "15m",
    },
  }),
  computed: {
        ChartDataStyles() {
      return {
        height: `${250}px`,
        width: this.computedWidth,
        position: "relative",
      };
    },
    computedCols() {
      if (this.$vuetify.breakpoint.xs) {
        return "12";
      } else if (this.$vuetify.breakpoint.sm) {
        return "6";
      } else if (this.$vuetify.breakpoint.md) {
        return "6";
      } else if (this.$vuetify.breakpoint.lg) {
        return "3";
      } else if (this.$vuetify.breakpoint.xl) {
        return "3";
      } else {
        return "1";
      }
    },
    computedWidth() {
      console.log(this.$vuetify.breakpoint.name);
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return 290;
        case "sm":
          return 450;
        case "md":
          return 450;
        case "lg":
          return 450;
        case "xl":
          return 450;
        default:
          return 450;
      }
    },
  },
  filters: {
    test: function (data) {
      console.log(data);
      return data.replace(".", ",");
    },
  },
  methods: {
    fetchDevices: function () {
      const requestOptions = {
        method: "POST",
        headers: {
          "x-access-token": this.$store.state.token,
          "Content-Type": "application/json",
        },
        body: JSON.stringify("fetchDevices"),
      };
      fetch(
        location.protocol + "//" + this.misc.hostname + "/api/devices/get",
        requestOptions
      )
        .then((response) => response.json())
        .then((data) => {
          this.devices = data;
          this.setChartGradient();
          this.fetchDeviceMetrics();
        })
        .catch((e) => {
          this.misc["snackbarText"] = "Error " + e;
          this.misc["snackbar"] = true;
        });
    },
    fetchDeviceMetrics: function () {
      const headers = {
        "x-access-token": this.$store.state.token,
        "Content-Type": "application/json",
      };
      const body = JSON.stringify({
        devices: this.devices,
        startTime: this.misc.startTime,
        step: this.misc.step,
      });

      axios
        .post(
          location.protocol +
            "//" +
            this.misc.hostname +
            "/api/v2/devices/metrics/get",
          body,
          { headers }
        )
        .then((response) => response.data)
        .then((data) => {
          //warum trenn ich das überhaupt?
          //this.devices = data;
          //this.sumHerd(data)
          //----------------------------
          this.devices = this.modifyForChartJs(data);
          
        })
        .catch((error) => {
          this.misc["snackbarText"] = "Error " + error.message;
          this.misc["snackbar"] = true;
          this.fetchDevices();
        });
    },
    timeRangeMeter: function () {
      this.misc.startTime = this.range + "h";
      let calcStep = parseInt(this.range / 30);
      if (calcStep <= 0) {
        this.misc.step = "15m";
      } else {
        this.misc.step = calcStep + "h";
      }

      console.log("asdaaaaaaaaaaaa", this.misc.startTime, this.misc.step);
      this.fetchDeviceMetrics();
    },
    setChartType: function () {
      if (this.chartType == "AreaChart") {
        this.chartType = "BarChart";
      } else {
        this.chartType = "AreaChart";
      }
    },
    modifyForChartJs: function(metricsDevices) {
  for (let key in metricsDevices) {
    for (const element of metricsDevices[key]) {
      if (element.metrics) {
        // Chart for power
        if (element.metrics.power && !element.metrics.chartJsPower?.datasets[0]) {
          element.metrics.chartJsPower = {
            datasets: [],
            labels: [],
          };
          const powerDataset = {
            data: element.metrics.power.datasets[0].data,
            yAxisID: "A",
            fill: true,
            tension: 0.5,
            borderColor: "#FC2525",
            borderWidth: 1,
            pointBorderColor: "white",
            backgroundColor: this.gradientRed,
            label: "power",
            pointHoverRadius: 15,
          };
          element.metrics.chartJsPower.datasets[0] = powerDataset;
          element.metrics.chartJsPower.labels = element.metrics.power.labels;
        }
        // Chart for temperature and humidity
        if (element.metrics.envData) {
          element.metrics.chartJsEnv = {
            datasets: [],
            labels: [],
          };
          const tempDataset = {
            data: element.metrics.envData.datasets[1].data,
            yAxisID: "A",
            fill: true,
            tension: 0.5,
            borderColor: "#FC2525",
            borderWidth: 1,
            pointBorderColor: "white",
            backgroundColor: this.gradientRed,
            label: "temp",
            pointHoverRadius: 15,
          };
          const humidDataset = {
            data: element.metrics.envData.datasets[0].data,
            yAxisID: "B",
            fill: true,
            tension: 0.5,
            borderColor: "#05CBE1",
            borderWidth: 1,
            pointBorderColor: "white",
            backgroundColor: this.gradientBlue,
            label: "humid",
            pointHoverRadius: 15,
          };
          element.metrics.chartJsEnv.datasets[0] = tempDataset;
          element.metrics.chartJsEnv.datasets[1] = humidDataset;
          element.metrics.chartJsEnv.labels = element.metrics.envData.labels;
        }
      }
    }
  }
  return metricsDevices;
},
    setChartGradient: function () {
      this.gradientRed = this.$refs.canvas
        .getContext("2d")
        .createLinearGradient(0, 0, 0, 450);
      this.gradientBlue = this.$refs.canvas
        .getContext("2d")
        .createLinearGradient(0, 0, 0, 450);

      this.gradientRed.addColorStop(0.1, "rgba(255, 0,0, 0.5)");
      this.gradientRed.addColorStop(0.5, "rgba(255, 0, 0, 0.25)");
      this.gradientRed.addColorStop(1, "rgba(145, 67, 204, 0.46)");

      this.gradientBlue.addColorStop(0, "rgba(0, 231, 255, 0.9)");
      this.gradientBlue.addColorStop(0.5, "rgba(0, 231, 255, 0.25)");
      this.gradientBlue.addColorStop(1, "rgba(0, 231, 255, 0)");
    },
    checkHost: function () {
      console.log(location.host);
      if (
        location.host == "badboard.badcloud.eu" ||
        location.host == "test.badcloud.eu"
      ) {
        this.$store.state.globalData.hostname = location.host;
        this.misc.hostname = this.$store.state.globalData.hostname;
      } else {
        this.$store.state.globalData.hostname = "192.168.0.106:7000";
        this.misc.hostname = this.$store.state.globalData.hostname;
      }
      console.log(this.$store.state.globalData.hostname);
    },
  },
  watch: {
    sliderValueWatch: function (a, b) {
      console.log(a, b, this.misc.switchValue);
    },
    menu(val) {
      val && setTimeout(() => (this.activePicker = "YEAR"));
    },
    range: function (a, b) {
      console.log(a, b);
      this.timeRangeMeter();
    },
  },
  mounted() {
    this.checkHost();
      this.fetchDevices();
      (this.intervalid1 = setInterval(this.fetchDeviceMetrics, 15000));
  },
  components: {
    AreaChart,
  },
};
</script>


