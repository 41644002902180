import App from './App'
import router from "./router";
import Vue from 'vue'
import vuetify from '@/plugins/vuetify' // path to vuetify export
import i18n from '@/plugins/i18n'
import "./assets/css/global.css";
import { store } from './store/store'
import './registerServiceWorker'


Vue.config.productionTip = false

new Vue({
    vuetify,
    i18n,
    router,
    store,
    render: h => h(App),
}).$mount('#app')
